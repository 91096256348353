import renderDate from '@swipekit/lib/render-date';
import { parseISO, format } from 'date-fns';
/**
 *
 * Pass a block and optionally an ad object, and this function will spit out all relevant stats
 * @returns Object
 */

function assetRatio(block) {
	let assetRatio = 1;
	if (block && block.assetRatio) {
		assetRatio = block.assetRatio;
	}

	let padding = assetRatio * 100;

	return padding;
}

function adBlockType(block) {
	let type = block.type;
	type = type.charAt(0).toUpperCase() + type.slice(1);

	return type;
}

function adCopy(block) {
	let adCopy = block.adCopy;

	if (!adCopy) {
		return null;
	}

	if (adCopy.includes('&#') && adCopy.length < 200) {
		return null;
	}

	return adCopy;
}

function adTitle(block) {
	return block.adTitle;
}

function sourceUrl(block) {
	if (block.source && typeof block.source === 'string') {
		return block.source;
	}

	return null;
}

function createdAt(block) {
	let createdAt = block.createdAt;

	if (!createdAt) {
		return '';
	}

	let str = renderDate(createdAt);

	return str;
}

function adActiveStatus(block) {
	let s = '';
	if (block.status === 'ACTIVE') {
		s = 'Active';
	}
	if (block.status === 'INACTIVE') {
		s = 'Inactive';
	}

	return s;
}

function adActiveDays(block) {
	if (block.type === 'facebook') {
		if (block.activeDays > 0) {
			return block.activeDays;
		}
		return 0;
	} else if (block.type === 'tiktok-library') {
		if (block.activeDays > 0) {
			return block.activeDays;
		}
		return 0;
	} else {
		return null;
	}
}

function adSpend(block) {
	if (block.type === 'facebook' && block.meta && block.meta.isEuAd) {
		try {
			let spend = Math.round(block.meta.eu_ad_spend);

			if (spend === 0) {
				spend = 1;
			}

			spend = spend.toLocaleString('en-US');

			return spend;
		} catch (err) {
			console.log(err);
			console.log(block);
			return null;
		}
	} else {
		return null;
	}
}

function adReach(block) {
	if (block.type === 'facebook' && block.meta && block.meta.isEuAd) {
		try {
			let reach = block.meta.eu_total_reach;

			reach = reach.toLocaleString('en-US');

			return reach;
		} catch (err) {
			console.log(err);
			console.log(block);
			return null;
		}
	} else {
		return null;
	}
}

function assetType(block) {
	let str = block.assetType;
	if (block.meta) {
		if (block.type === 'facebook' && block.meta.dco) {
			str = 'dco';
		}
		if (block.type === 'facebook' && block.meta.dpa) {
			str = 'dpa';
		}
		if (block.type === 'facebook' && block.meta.multi_images) {
			str = 'Multiple images';
		}
		if (block.type === 'facebook' && block.meta.multi_medias) {
			str = 'Multiple medias';
		}
		if (block.type === 'facebook' && block.meta.page_like) {
			str = 'Page like';
		}
	}

	if (block.type === 'google') {
		if (block.assets[0]) {
			str = block.assets[0].type;
		}
	}

	if (!str) {
		str = 'image';
	}

	try {
		str = str.charAt(0).toUpperCase() + str.slice(1);
	} catch (err) {
		console.log(err);
		console.log(block);
		str = '';
	}

	return str;
}

function comments(block) {
	if (block.type === 'tiktok-top-ads' || block.type === 'tiktok' || block.assetType === 'youtube') {
		let comments = block.comments || 0;

		return comments;
	}

	return null;
}

function likes(block) {
	if (block.type === 'tiktok-top-ads' || block.type === 'tiktok' || block.assetType === 'youtube') {
		let likes = block.likes || 0;

		return likes;
	}

	return null;
}

function shares(block) {
	if (block.type === 'tiktok-top-ads' || block.type === 'tiktok') {
		let shares = block.shares || 0;

		return shares;
	}

	return null;
}

function views(block) {
	if (block.type === 'google') {
		let views = block.views || 0;

		return views;
	}

	return null;
}

function savedOn(block) {
	let createdAt = block.createdAt;

	if (!createdAt) {
		return '';
	}

	const parsedDate = parseISO(createdAt);

	const formattedDate = format(parsedDate, 'MMMM dd, yyyy');

	return formattedDate;
}

export default function (block) {
	let obj = {
		assetRatio: assetRatio(block),
		adBlockType: adBlockType(block),
		adCopy: adCopy(block),
		adTitle: adTitle(block),
		sourceUrl: sourceUrl(block),
		createdAt: createdAt(block),
		adActiveStatus: adActiveStatus(block),
		adActiveDays: adActiveDays(block),
		adSpend: adSpend(block),
		adReach: adReach(block),
		assetType: assetType(block),
		comments: comments(block),
		likes: likes(block),
		shares: shares(block),
		views: views(block),
		savedOn: savedOn(block),
		views: views(block),
	};

	return obj;
}
