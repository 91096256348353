<template>
	<div class="c-board-list">
		<SearchBar :boards="true" v-model="params" @search="onSearch" @view="onView"></SearchBar>
		<div v-masonry="containerId" transition-duration="0s" item-selector=".c-grid-item">
			<Item v-for="block in computedBlocks" :view="view" :ad="block" :key="block.id" @onSubmit="onSubmit" @onDropdownClick="onDropdownClick" v-masonry-tile></Item>
		</div>
		<EmptyState v-if="blocks.length === 0 && !init"> </EmptyState>
		<PopupBlockOptions @onEdit="onEdit" type="board" @onDelete="onDelete" @onClose="onPopupClose" :adId="popupAdId" :target="popupTarget" :active="popupActive"></PopupBlockOptions>
	</div>
</template>

<script>
import SearchBar from '@swipekit/components/utils/search-bar';
import Item from '@swipekit/components/item';

import filter from '@swipekit/lib/filter';
import { getParams, setParams } from '@swipekit/lib/query-param-utils';
import PopupBlockOptions from '@swipekit/components/popup/popup-block-options';

import api from '@swipekit/lib/api';

import EmptyState from './empty-state';

export default {
	components: {
		SearchBar,
		Item,
		PopupBlockOptions,
		EmptyState,
	},

	data: function () {
		return {
			containerId: null,
			params: {
				query: '',
				network: 'all',
				mediaType: 'all',
				activeAds: 0,
			},
			blocks: [],
			skip: 0,
			take: 20,

			view: '',

			processing: false,

			popupActive: false,
			popupTarget: null,
			popupAdId: null,

			init: false,
		};
	},

	props: {
		adIds: {
			type: Array,
			default: function () {
				return [];
			},
		},
		board: {},
	},

	watch: {
		board: function () {
			this.init = false;
			this.blocks = [];
			this.skip = 0;
			this.take = 20;
			this.filter(null, true).then(() => {
				this.init = true;
			});
		},
		removeId: function () {
			if (this.removeId) {
				this.blocks = this.blocks.filter((obj) => {
					return obj.id !== this.removeId;
				});
			}
		},
		removeSelectionTrigger: function () {
			const selection = this.$store.getters['ads/selection'] || [];

			if (selection.length === 0) {
				return;
			}

			let blocks = JSON.parse(JSON.stringify(this.blocks));

			blocks = blocks.filter((obj) => !selection.includes(obj.id));

			this.blocks = blocks;
		},
	},

	computed: {
		removeSelectionTrigger: function () {
			return this.$store.getters['ads/removeSelectionTrigger'];
		},
		removeId: function () {
			return this.$store.getters['ads/removeId'];
		},
		computedBlocks: function () {
			let blocks = this.blocks;

			if (!blocks) {
				return [];
			}

			let ids = this.adIds;

			for (let i = 0; i < blocks.length; i++) {
				let isSaved = false;

				if (ids.includes(blocks[i].block.id)) {
					isSaved = true;
				}

				blocks[i].isSaved = isSaved;
			}

			return blocks;
		},
		// processing: function () {
		//   return this.$store.getters["app/processing"];
		// },
	},

	methods: {
		onEdit: function (ad) {
			delete ad.updatedAt;
			ad.boards = [];
			const id = ad.id;
			const index = this.blocks.findIndex((item) => item.id === id);

			if (index !== -1) {
				// Update the properties of the object
				this.blocks[index] = ad;

				// Trigger Vue reactivity using this.$set
				this.$set(this.blocks, index, this.blocks[index]);
			}
			this.$redrawVueMasonry();
		},
		onDelete: async function (e) {
			this.onPopupClose();

			let res = await this.$store.dispatch('ads/remove', e).then((err) => {
				console.log(err);
			});
		},
		onPopupClose: function () {
			this.popupTarget = null;
			this.popupActive = false;
			this.popupAdId = null;
		},
		onDropdownClick: function (e) {
			const el = document.querySelector(`.${e.tempClass}`);

			let ad = null;

			for (let i = 0; i < this.blocks.length; i++) {
				const adBlock = this.blocks[i];
				if (adBlock.id === e.id) {
					ad = adBlock;
					break;
				}
			}

			if (!el) {
				console.log('err el not found');
				return;
			}

			this.popupTarget = el;
			this.popupActive = true;
			this.popupAdId = ad;
		},
		async onView(view) {
			this.view = view;

			api.api.setOption('view', view);

			this.$nextTick(() => {
				this.$redrawVueMasonry();
			});
		},
		onSubmit: function (e) {
			this.$emit('onSubmit', e);
		},
		applyParams: function (params) {
			if (params.query) {
				this.params.query = params.query;
			}

			if (params.skip) {
				this.skip = params.skip;
			}
			if (params.take) {
				this.take = params.take;
			}

			if (params.mediaType) {
				this.params.mediaType = params.mediaType;
			}

			if (params.activeAds) {
				this.params.activeAds = params.activeAds;
			}

			if (params.network) {
				this.params.network = params.network;
			}
		},
		onEnd: function () {
			if (this.processing) {
				return;
			}
			this.skip += this.take;
			this.filter();
		},
		async onSearch(params) {
			this.blocks = [];
			this.skip = 0;
			await this.filter(params);
		},
		async filter(params, firstTime = false) {
			if (this.processing) {
				return;
			}
			this.$store.dispatch('app/setProcessing', true);

			if (firstTime) {
				params = getParams();

				// if first time, force 1st page
				params.skip = 0;

				this.applyParams(params);
			}
			if (!params) {
				params = getParams();
			}

			params.skip = this.skip;
			params.take = this.take;

			setParams(params);

			const blocks = await filter(params, `filter-board/${this.board.slug}`);
			this.blocks = this.blocks.concat(blocks);

			this.$nextTick(() => {
				this.$redrawVueMasonry();
				this.$store.dispatch('app/setProcessing', false);
			});
		},
	},

	mounted: async function () {
		this.skip = 0;
		this.take = 20;
		this.filter(null, true);

		let options = await api.api.getOptions();

		this.view = options.view || 'all';
	},
};
</script>

<style lang="scss">
.c-board-list {
}
</style>
