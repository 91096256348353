<template>
  <div class="r-facebook">
    <!-- <article v-if="firstAsset && assetType === 'text'">
      {{ block.adCopy }}
    </article> -->
    <video v-if="firstAsset && assetType === 'video'" :poster="poster" controls>
      <source v-if="firstAsset.assetPath" :src="`${assetUrl}/${firstAsset.assetPath}`" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <img v-if="firstAsset && assetType === 'image'" :src="`${assetUrl}/${firstAsset.assetPath}`" />
    <a v-if="!hideDownload && firstAsset && assetType !== 'carousel'" class="c-renderer__download" :href="downloadUrl(firstAsset.assetPath)">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V13.1893L8.53033 10.4697C8.23744 10.1768 7.76256 10.1768 7.46967 10.4697C7.17678 10.7626 7.17678 11.2374 7.46967 11.5303L11.4697 15.5303C11.7626 15.8232 12.2374 15.8232 12.5303 15.5303L16.5303 11.5303C16.8232 11.2374 16.8232 10.7626 16.5303 10.4697C16.2374 10.1768 15.7626 10.1768 15.4697 10.4697L12.75 13.1893V6ZM8 17.25C7.58579 17.25 7.25 17.5858 7.25 18C7.25 18.4142 7.58579 18.75 8 18.75H16C16.4142 18.75 16.75 18.4142 16.75 18C16.75 17.5858 16.4142 17.25 16 17.25H8Z"
          fill="currentColor"
        />
      </svg>
    </a>

    <carousel :navigationEnabled="true" v-if="firstAsset && assetType === 'carousel'" :perPage="1">
      <slide v-for="(asset, key) in block.assets" :key="key">
        <a v-if="!hideDownload" class="c-renderer__download" :href="downloadUrl(asset.assetPath)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V13.1893L8.53033 10.4697C8.23744 10.1768 7.76256 10.1768 7.46967 10.4697C7.17678 10.7626 7.17678 11.2374 7.46967 11.5303L11.4697 15.5303C11.7626 15.8232 12.2374 15.8232 12.5303 15.5303L16.5303 11.5303C16.8232 11.2374 16.8232 10.7626 16.5303 10.4697C16.2374 10.1768 15.7626 10.1768 15.4697 10.4697L12.75 13.1893V6ZM8 17.25C7.58579 17.25 7.25 17.5858 7.25 18C7.25 18.4142 7.58579 18.75 8 18.75H16C16.4142 18.75 16.75 18.4142 16.75 18C16.75 17.5858 16.4142 17.25 16 17.25H8Z"
              fill="currentColor"
            />
          </svg>
        </a>
        <img v-if="asset.type === 'image'" :src="`${assetUrl}/${asset.assetPath}`" />
        <video v-else controls>
          <source v-if="asset.assetPath" :src="`${assetUrl}/${asset.assetPath}`" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </slide>
    </carousel>

    <!-- <div class="c-renderer__labels">
      <label> {{ displayFormat }} </label>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    block: {},
    hideDownload: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    displayFormat: function () {
      let block = this.block;
      if (block.meta && block.meta.dco) {
        return 'DCO';
      } else {
        return block.assetType;
      }
    },
    baseUrl: function () {
      return this.$store.getters['config/baseUrl'];
    },

    poster: function () {
      if (!this.firstAsset) {
        return;
      }

      let poster = this.firstAsset.cover;

      if (!poster) {
        return;
      }

      return `${this.assetUrl}/${this.firstAsset.cover.assetPath}`;
    },
    firstAsset: function () {
      let block = this.block;

      if (!block.assets) {
        return null;
      }

      let asset = block.assets[0];

      return asset;
    },
    // Guesses what kind of asset overall this block is about. Does a good enough job for now
    assetType: function () {
      return this.block.assetType;
      let firstAsset = this.firstAsset;
      let block = this.block;
      let assetType = '';
      if (block.assets && block.assets.length > 1) {
        assetType = 'carousel';
      } else {
        assetType = firstAsset.type;
      }
      return assetType;
    },
    assetUrl: function () {
      return this.$store.getters['config/assetUrl'];
    },
  },

  methods: {
    downloadUrl: function (fileName) {
      let baseUrl = this.baseUrl;

      let str = `${this.assetUrl}/${fileName}`;

      return str;
    },
  },
};
</script>

<style lang="scss">
.r-facebook {
  width: 100%;
  height: 100%;
  position: relative;

  img,
  video {
    display: block;
    width: 100%;
    height: 100%;
  }

  // > article {
  //   width: 100%;
  //   height: 100%;
  //   overflow-y: auto;
  //   background-color: #fff;
  //   font-size: var(--font-size-xs);
  //   white-space: break-spaces;
  //   border-top: var(--color-bg-3) solid 1px;
  //   border-bottom: var(--color-bg-3) solid 1px;
  // }
}
</style>
