import api from '@swipekit/lib/api';
import parseISO from 'date-fns/parseISO';
import differenceInDays from 'date-fns/differenceInDays';
import sub from 'date-fns/sub';
import { parse } from 'date-fns';

let store = {
	namespaced: true,
	state: {
		user: {},
		isExtension: false,
	},
	getters: {
		user: function (state) {
			return state.user;
		},
		isAuth: function (state) {
			if (state.user && state.user.id) {
				return true;
			}
			return false;
		},
		trialStarted: function (state, getters, rootState, rootGetters) {
			let workspace = rootGetters['workspaces/all'];

			if (!workspace) {
				return false;
			}

			return workspace.trialStartedAt;
		},
		is3DaysAfterTrialStarted: function (staate, getters) {
			let trialStarted = getters['trialStarted'];

			if (!trialStarted) {
				return;
			}

			let trialDate = parseISO(trialStarted);

			let dayDifference = differenceInDays(new Date(), trialDate);

			if (dayDifference > 2) {
				return true;
			}

			return false;
		},
		trialStartedDate: function (state, getters, rootState, rootGetters) {
			let workspace = rootGetters['workspaces/all'];

			if (!workspace) {
				return false;
			}

			let date = parseISO(workspace.trialStartedAt);

			return date;
		},
		trialStartedDifference: function (state, getters, rootState, rootGetters) {
			let workspace = rootGetters['workspaces/all'];

			if (!workspace) {
				return false;
			}

			let date = workspace.trialStartedAt;

			if (!date) {
				return;
			}

			date = parseISO(date);

			let trialLength = workspace.trialLimit;

			let trialEndDate = sub(new Date(), {
				days: trialLength + 1,
			});

			let diff = differenceInDays(date, trialEndDate);

			return diff;
		},
		isTrialOver: function (state, getters, rootState, rootGetters) {
			let workspace = rootGetters['workspaces/all'];
			if (!workspace) {
				return false;
			}

			if (workspace.plan === 'DEACTIVATED') {
				return true;
			}
			return false;
			// let diff = getters.trialStartedDifference;

			// if (diff > state.trialLength) {
			//   return true;
			// }
			// return false;
		},
		isPaid: function (state, getters, rootState, rootGetters) {
			let workspace = rootGetters['workspaces/all'];
			if (!workspace) {
				return false;
			}
			if (workspace.plan === 'PRO') {
				return true;
			}

			if (workspace.isPaying === true) {
				return true;
			}
			return false;
		},
		isAdmin: function (state, getters, rootState, rootGetters) {
			return rootGetters['workspaces/isAdmin'];
		},
	},
	mutations: {
		SET: function (state, user) {
			if (user) {
				state.user = user;
			} else {
				state.user = {};
			}
		},
	},
	actions: {
		async init(store, opts = {}) {
			store.dispatch('hideCrisp');

			let isExtension = opts.isExtension || false;
			store.state.isExtension = isExtension;

			let res = await store.dispatch('headlessLogin').catch((err) => {});

			return res;
		},

		async showCrisp(store) {
			//await new Promise((r) => setTimeout(r, 1000));
			// check if workspace is paid
			let user = store.getters['user'];

			if (!user) {
				return;
			}

			let workspace = store.rootState.workspaces.resource;

			if (!workspace) {
				return;
			}

			let allowedPlans = ['PRO', 'TESTER'];

			let plan = workspace.plan;

			// Don't allow support for users who aren't activated
			if (!workspace.activated) {
				return;
			}

			// allow if allowtrial is true.
			if (workspace.meta && workspace.meta.allowTrialExtension) {
			} else {
				// Don't show chat widget to non-pro users
				// DISABLED TEMPORARILY until we turn this ship around
				// if (!allowedPlans.includes(plan)) {
				//   return;
				// }
			}

			// we have this to negate 'hideCrisp's lag
			setTimeout(() => {
				// First, hide chat
				if (typeof $crisp === 'undefined') {
					return;
				}

				$crisp.push(['do', 'chat:show']);
			}, 500);
		},

		async hideCrisp(store) {
			// we have this cuz crisp takes a minor delay to load
			setTimeout(() => {
				if (typeof $crisp === 'undefined') {
					return;
				}

				$crisp.push(['do', 'chat:hide']);
			}, 500);
		},

		async getUser(store) {
			let users = await api.users.get();
			if (users.length > 0) {
				store.commit('SET', users[0]);
			}
		},

		async setUser(store) {},

		async create(store, ad) {},

		async signup(store, form) {
			let config = store.rootState.config;
			form.meta = {
				browser: config.browser,
				mode: config.mode,
				screenX: config.screenX,
				screenY: config.screenY,
				os: config.os,
			};
			let pie = null;
			try {
				pie = await api.users.signup(form);
			} catch (err) {
				throw err;
			}

			if (!pie) {
				return;
			}

			const user = JSON.parse(JSON.stringify(pie));
			delete user.workspace;

			if (typeof rewardful !== 'undefined') {
				console.log(`CONVERTING ${user.email}`);
				rewardful('convert', { email: user.email });
			}

			store.dispatch('identify', user);

			store.commit('SET', user);

			store.dispatch('showCrisp');

			return pie;
		},

		async update(store, form) {
			let user = store.state.user;
			form.id = user.id;
			let response = null;
			try {
				response = await api.users.update(form, user.id);
			} catch (err) {
				throw err;
			}

			store.commit('SET', response);

			return response;
		},

		async updateMarketing(store) {
			let user = store.state.user;

			let marketing = user.marketing || {};

			let config = store.rootState.config;

			let screen = `${config.screenX}x${config.screenY}`;

			marketing.screen = screen;
			marketing.timezone = config.timezone;
			marketing.utcOffset = config.utcOffset;

			let form = {
				id: user.id,
				marketing: marketing,
			};

			user = await api.users.update(form).catch((err) => {
				console.log(err);
			});

			if (user) {
				store.commit('SET', user);
			}
		},

		/**
		 * Basically, gets the user if they are logged in
		 * If not logged in, returns null
		 * If logged in, sets the user
		 *
		 * Run this ideally, before init
		 */
		async headlessLogin(store) {
			let pie = null;
			if (!store.state.isExtension) {
				pie = await api.users.headlessLogin();
			} else {
				pie = await api.users.headlessChromeLogin();
			}

			if (!pie) {
				return;
			}
			const user = JSON.parse(JSON.stringify(pie));
			delete user.workspace;

			store.commit('SET', user);

			store.dispatch('identify', user);

			// store.dispatch("updateMarketing");

			//store.dispatch("afterLogin", null, { root: true });

			return pie;
		},

		async resetPassword(store, form) {
			let payload = null;
			try {
				payload = await api.users.resetPassword(form);
			} catch (err) {
				throw err;
			}

			return payload;
		},

		async updatePassword(store, form) {
			let user = null;
			try {
				user = await api.users.updatePassword(form);
			} catch (err) {
				throw err;
			}

			if (user) {
				store.commit('SET', user);
			}

			return user;
		},

		async updateUserPassword(store, form) {
			let user = null;
			try {
				user = await api.users.updateUserPassword(form);
			} catch (err) {
				throw err;
			}

			return user;
		},

		/**
		 * Logs in user using the form pass as payload
		 * If user logs in successfully, they are committed to the store
		 */
		async login(store, form) {
			let pie = null;
			try {
				pie = await api.users.login(form);
			} catch (err) {
				throw err;
			}

			if (!pie) {
				return;
			}

			const user = JSON.parse(JSON.stringify(pie));
			delete user.workspace;

			store.commit('SET', user);

			store.dispatch('identify', user);

			await store.dispatch('sendTokenToChromeExtension');

			return pie;
		},

		async sendTokenToChromeExtension(store, auth = true) {
			let extensionId = store.rootState.extensionId;
			let token = '';
			if (auth) {
				token = (await api.users.getToken()) || '';
			}
			if (!chrome.runtime) {
				return;
			}
			chrome.runtime.sendMessage(extensionId, { type: `auth`, jwt: token }, (response) => {});
		},

		async logout(store) {
			// Sometimes the server returns a 403, not sure how to handle it for now but the frontend assumes that the cookie is now invalid/user's session has been destroyed successfully
			let success = await api.users.logout();

			store.dispatch('message/onLogout', null, { root: true });

			// Somewhere over here also call afterLogout on the main store so other modules can flush their state out
			store.dispatch('afterLogout', null, { root: true });
			store.dispatch('sendTokenToChromeExtension', false);

			store.dispatch('hideCrisp');

			if (typeof posthog === 'undefined') {
				console.log('posthog undefined');
				return;
			}

			window.posthog.reset();
		},

		async afterLogout(store) {
			store.dispatch('clear');
		},

		async clear(store) {
			let def = null;

			store.commit('SET', def);
		},

		async upgrade(store, form) {
			let data = await api.users.upgrade(form);

			if (data && data.url) {
				window.location = data.url;
			}
		},

		async buy(store, obj) {
			let data = await api.users.buy(obj);

			if (data && data.url) {
				window.location = data.url;
			}
		},

		async getPie(store) {
			let user = store.state.user;

			if (!user) {
				return;
			}

			let pie = await api.users.pie();

			return pie;
		},

		async acceptInvite(store, form) {
			let response = await api.users.acceptInvite(form);

			if (response && response.data) {
				store.commit('SET', response.data);
			}

			return response.data;
		},

		async identify(store, user) {
			if (typeof posthog === 'undefined') {
				console.log('posthog undefined');
				return;
			}
			if (!window) {
				console.log('window undefined');
				return;
			}

			let secret = await api.app.chatSecret();

			if (window.$crisp) {
				$crisp.push(['set', 'user:email', [user.email, secret]]);
			}

			window.posthog.identify(user.id);
		},

		async delete(store) {
			let id = store.state.user.id;
			let response = await api.users.delete(id);
		},
	},
	modules: {},
};

export default store;
